import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["container"]
  
  connect() {
    //console.log("Connected", this.element)
    document.addEventListener("nextWord", event => this.resize())
    window.addEventListener('resize', event => this.resize())
    this.resize()
  }

  resize() {
      let docWidth = document.documentElement.offsetWidth;
      while (this.containerTarget.scrollWidth > docWidth) {
        let currentSize = window.getComputedStyle(this.containerTarget.children[0], null).getPropertyValue('font-size').substring(0,2)
        for (var el of this.containerTarget.children){
          el.style.fontSize=`${currentSize*0.95}px`
        }
      }
  }
}