import { Controller } from "stimulus"
import switchRightIcon from "../../icons/switch_right_icon.svg"
import switchLeftIcon from "../../icons/switch_left_icon.svg"
export default class extends Controller {
  static targets = ["norsk", "annet", "icon"]
  language = true
  
  connect() {
    //console.log("Connected", this.element)
    this.loadChoice()
    document.addEventListener("confirmSwitchFlip", event => this.handleConfirmSwitch())
  }

  disconnect() {
    document.removeEventListener("confirmSwitchFlip", this.handleConfirmSwitch())
  }

  loadChoice() {
    try {
      let checked = localStorage.getItem('language')
      if (checked==1) {
          this.language=false
          this.iconTarget.src=switchRightIcon
          const event = document.createEvent("CustomEvent")
            event.initCustomEvent("flipTable", true, true)
            document.dispatchEvent(event)
      }
      else {
        this.language=true
        localStorage.setItem('language', 0)
      }
  }
    catch{
        localStorage.setItem('language', 0)
        this.language=true
    }
  }

  handleClick() {
    if (localStorage.getItem('scoreList') != null) {
      /* const event = document.createEvent("CustomEvent")
        event.initCustomEvent("openModalWarningFlip", true, true)
        document.dispatchEvent(event) */
        this.handleConfirmSwitch();
    } else {
      this.handleConfirmSwitch();
    }
  }

  handleConfirmSwitch() {
    this.language = !this.language
    this.language ? localStorage.setItem('language', 0) : localStorage.setItem('language', 1)
    this.language ? this.iconTarget.src=switchLeftIcon : this.iconTarget.src=switchRightIcon
    const event = document.createEvent("CustomEvent")
      event.initCustomEvent("flipTable", true, true)
      document.dispatchEvent(event)
  }
}