import { Controller } from "stimulus"

export default class extends Controller {
  
  connect() {
    //console.log("Connected", this.element)
  }

  handleClick() {
    const event = document.createEvent("CustomEvent")
    event.initCustomEvent("openModalDelete", true, true)
    this.element.dispatchEvent(event)
  }
}