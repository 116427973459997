import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["display"]
  
  connect() {
    //console.log(this.displayTarget.innerHTML)
    this.startTimer()
  }

  disconnect() {
    this.stopTimer()
  }

  startTimer() {
    let startTime;
    let elapsedTime = 0;
    console.log("startTimer" + Date.now());
    let timerInterval;
    startTime = Date.now() - elapsedTime;
    let element = this.displayTarget;
    timerInterval = setInterval(() => {
        elapsedTime = Date.now() - startTime;

        let diffInHrs = elapsedTime / 3600000;
        let hh = Math.floor(diffInHrs);
        let diffInMin = (diffInHrs - hh) * 60;
        let mm = Math.floor(diffInMin);
        let diffInSec = (diffInMin - mm) * 60;
        let ss = Math.floor(diffInSec);
        let formattedMM = mm.toString().padStart(2, "0");
        let formattedSS = ss.toString().padStart(2, "0");

        element.innerHTML = (`${formattedMM}:${formattedSS}`);
    }, 100);
  }

  stopTimer(){
      elapsedTime=0;
      clearInterval(timerInterval);
      this.displayTarget.innerHTML = '00:00'
  }
}