import { Controller } from "stimulus"
import html2canvas from 'html2canvas';


export default class extends Controller {
  static targets = ["shareURL", "wordPairs", "wordListNameInput", "wordListName"]
  static values = { 
    url: String,
    name: String,
  }


  connect() {
    this.urlObject = new URL(window.location)
    this.makeUrlParams()
    this.loadWordPairs()
    this.wordListNameInputTarget.placeholder = __("EKS: 4B, UKE 32")

    console.log(this.urlObject);
    this.shareURLTarget.innerHTML = decodeURI(this.urlObject.href).replace("/share.html", "")

    window.addEventListener("updateURLParams", e => {
      this.urlObject.search = ""
      let wordList = JSON.parse(localStorage.getItem('wordList'))
      for (const key in wordList) {
          const value = wordList[key];
          this.urlObject.searchParams.set(encodeURI(key), encodeURI(value))
      }
      this.shareURLTarget.innerHTML = decodeURI(this.urlObject.href).replace("/share.html", "")
      this.loadWordPairs();
      this.updateQRCode();
    })
  }

  copy() {
    var text = decodeURI(this.urlObject.href.replace(/^ss*/, '').replace(/ss*$/, '')).replace("/share.html", "")
    navigator.clipboard.writeText(text).then(function() {
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  }

  updateQRCode() {
    const event = document.createEvent("CustomEvent")
    event.initCustomEvent("updateQRCode", true, true, {urlValue: decodeURI(this.urlObject.href.replace(/^ss*/, '').replace(/ss*$/, ''))})
    document.dispatchEvent(event)
  }

  downloadQR() {
    html2canvas(document.querySelector("#capture")).then(canvas => {
        var link = document.createElement('a');
        link.download = 'glosepus.png';
        link.href = canvas.toDataURL()
        link.click();
    });
  }

  saveWordListName(e) {
    if (e.target.value == "") {
      this.urlObject.searchParams.delete("wordListName")
      this.wordListNameTarget.innerHTML = ""
    } else {
      this.wordListNameInput = e.target.value
      this.urlObject.searchParams.set("wordListName", this.wordListNameInput)
      this.wordListNameTarget.innerHTML = this.wordListNameInput.toUpperCase()
    }
    this.shareURLTarget.innerHTML = decodeURI(this.urlObject.href).replace("/share.html", "")
    this.updateQRCode();
  }

  loadWordPairs() {
    this.wordPairsTarget.innerHTML = ""
    let pairs = JSON.parse(localStorage.getItem('wordList'))
    for (const pair in pairs) {
      if (pair != "name") {
        var div = document.createElement('p');
        div.innerHTML = pair + " - " + pairs[pair];
        div.className = 'wordPair';
        
        this.wordPairsTarget.appendChild(div);
      }
    }
  }

  makeUrlParams() {
    let wordList = JSON.parse(localStorage.getItem('wordList'))

    Object.keys(wordList).forEach(element => {
      this.urlObject.searchParams.set(element, wordList[element])
    });
  }
}