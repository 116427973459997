import { Controller } from "stimulus"

export default class extends Controller {
  
  static targets = ["nameHolder"]

  connect() {
    let wordListName = localStorage.getItem('wordListName')
    try {
      if (wordListName != undefined) {
        this.showWordListName(wordListName);
      }
    } catch {

    }
  }

  showWordListName(wordListName) {
    this.element.classList.remove("hide")
    this.nameHolderTarget.innerHTML = wordListName.toUpperCase().replace("+", " ");
  }

}