import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["inputField", "rowNumber", "wordTable", "tableColumn"]
    static values = {wordList: Boolean}
    rowCounter = 8
    urlParams = ""

    //static values = {rowId: String}
  
    connect() {
        document.addEventListener("confirmSwitchLoad", event => this.handleConfirmSwitch())
        document.addEventListener("flipTable", e => this.flipTable())
        document.addEventListener("modalInit", e => this.handleLoadUrlParams())

        this.urlParamObject = this.getUrlParams()
        this.selectedRow = 6
        this.changedWordList = false

        if (window.innerWidth < 600) {
            this.inputFieldTargets.forEach((element, index) => {
                if (index >= 8 && index <= 13 && index % 2 == 0) {
                    element.setAttribute("data-action" , "change->table#saveWords change->table#showNewRow")
                }
            })
        }

        this.loadWords();
    }

    handleLoadUrlParams() {
        if (Object.keys(this.urlParamObject).length != 0) {
            const event = document.createEvent("CustomEvent")
                event.initCustomEvent("openModalWarningLoad", true, true)
                this.element.dispatchEvent(event)
        }
    }


    handleConfirmSwitch() {
        let urlParamObject = this.getUrlParams()
        localStorage.setItem('wordList', JSON.stringify(urlParamObject))
        this.loadWords();

        const event = document.createEvent("CustomEvent")
            event.initCustomEvent("showWordListName", true, true)
            document.dispatchEvent(event)
    }

    wordListValueChanged() {
        if (this.wordListValue) {
            localStorage.removeItem('scoreList')
        }
    }

    showNewRow(event) {
        let row = this.wordTableTarget.querySelectorAll("tr")[this.selectedRow]
        row.style.display = "table-row"
        this.selectedRow += 1
    }

    getUrlParams() {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,   
        function(m,key,value) {
            if (key != "wordListName") {
                let strippedStringValue = decodeURI(value).replace(/(<([^>]+)>)/gi, "");
                let strippedStringKey = decodeURI(key).replace(/(<([^>]+)>)/gi, "");
                vars[strippedStringKey] = strippedStringValue;
                console.log(parts);
            } else {
                localStorage.setItem('wordListName', decodeURI(value).replace(/(<([^>]+)>)/gi, ""))
            }
        });
        delete vars["fbclid"]
        return vars;
    }
    
    flipTable() {
        let field = document.getElementById("tableHeadline")
        field.appendChild((field.querySelector("th")))

        this.inputFieldTargets.forEach((element, index) => {
            if (index%2) {
                let leftValue = this.inputFieldTargets[index - 1].value;
                let rightValue = this.inputFieldTargets[index].value;
                this.inputFieldTargets[index - 1].value = rightValue
                this.inputFieldTargets[index].value = leftValue
            }
        });
    }
    

    reset() {
        this.wordListValue = true
        
        this.inputFieldTargets.forEach(element => {
        element.value='';
        });
        //this.TableRows();
        localStorage.removeItem('wordList','')
        localStorage.removeItem('wordListName','')
        this.loadWords()
        //this.rowCounter=8
        //console.log(' wordlist')
        location.href="./index.html"
        this.inputFieldTargets[0].focus()
    }

    TableRows () {
        this.rowNumberTargets.forEach(element => {
        element.classList.add('disabled');
        });
        for (let i=2; i<(this.inputFieldTargets.length); i++) {
        this.inputFieldTargets[i].disabled=true;
        }
    }

    loadWords(){
        let count = 0
        let savedWords = JSON.parse(localStorage.getItem('wordList'))
        let inputList = this.inputFieldTargets
        if(savedWords!=null){
            for (var key in savedWords){
                if (key != "wordListName") {
                    if (count>=16){
                        this.insertNewRow()
                        inputList = this.inputFieldTargets
                    }
                    inputList[count].value=key;
                    inputList[count+1].value=savedWords[key];
                    count+=2
                }
            }
        }
        if (count>=16){
            this.insertNewRow()
            inputList = this.inputFieldTargets
        }
        for (var i=0; i<inputList.length; i+=2){
            if (inputList[i].value==''){
                inputList[i].parentElement.children[0].classList.add('disabled')
            } else {
                inputList[i].parentElement.children[0].classList.remove('disabled')
            }
        }
        inputList[count].focus()
    }

    enableRow(event) {
        this.disableEmptyRow()
        event.currentTarget.parentElement.parentElement.children[0].children[0].classList.remove('disabled')
        this.disableEmptyRow()
    }

    disableEmptyRow(){
        let inputList = this.inputFieldTargets
        for (var i=0; i<inputList.length; i+=2){
            if (inputList[i].value=='' || inputList[i+1].value==''){
                inputList[i].parentElement.children[0].classList.add('disabled')
            }
        }
    }

    saveWords() {
        this.wordListValue = true 
        let input = this.inputFieldTargets;
        let wordList = {};
        let keyWord='';
        let valueWord='';
        this.urlParams = '?'
        for (let i = 1; i< input.length; i+=2){
            if (input[i-1].value.length>0 && input[i].value.length>0){
                keyWord=input[i-1].value.toLowerCase().trim().replace(/(<([^>]+)>)/gi, "");
                valueWord=input[i].value.toLowerCase().trim().replace(/(<([^>]+)>)/gi, "");

                wordList[keyWord]=valueWord;
            }
        }
        this.cleanUpTable()
        this.disableEmptyRow();
        localStorage.setItem('wordList', JSON.stringify(wordList))
        const event = document.createEvent("CustomEvent")
            event.initCustomEvent("updateURLParams", true, true)
            document.dispatchEvent(event)
    }

    cleanUpTable() {
        let input = this.inputFieldTargets;
        for (let i = 1; i< input.length; i+=2){
            if (input[i-1].value.length == 0 && input[i].value.length == 0) {
                try {
                    for (let index = i; index < input.length; index++) {
                        const element = input[index];
                        if (element.value.length > 0) {
                            input[index - 2].value = element.value
                            element.value = null
                            input[index - 2].parentElement.parentElement.children[0].children[0].classList.remove('disabled')
                        }
                    }
                } catch {}
            }
        }
        try {
            if (this.inputFieldTargets[this.inputFieldTargets.length - 5].value.length == 0 && this.inputFieldTargets.length > 16) {
                this.inputFieldTargets[this.inputFieldTargets.length - 1].parentElement.parentElement.remove();
                this.rowCounter--
            }
        } catch {}
    }

    insertNewRow() {
        if ((Object.keys(JSON.parse(localStorage.getItem('wordList'))).length)>=this.rowCounter - 1){
            let newRow = this.wordTableTarget.insertRow();
            let cell1 = newRow.insertCell(0);
            let cell2 = newRow.insertCell(1);
            let pElement = document.createElement('p');
            let inputElement1 = document.createElement("input");
            let inputElement2 = document.createElement("input");
            newRow.setAttribute("data-action" , "change->table#saveWords change->table#insertNewRow")
            newRow.style.display = "table-row"
            inputElement1.type='text';
            inputElement1.classList.add('leftSide');
            inputElement2.type='text';
            inputElement1.setAttribute("data-Words-target", "inputField");
            inputElement2.setAttribute("data-Words-target", "inputField");
            inputElement1.setAttribute("data-table-target", "inputField");
            inputElement2.setAttribute("data-table-target", "inputField");
            inputElement1.setAttribute("data-action","change->table#enableRow")
            inputElement2.setAttribute("data-action","change->table#enableRow")
            pElement.setAttribute("data-Words-target", "rowNumber");
            pElement.innerHTML = (this.rowCounter+1);
            pElement.classList.add("disabled")
            cell1.appendChild(pElement);
            cell1.appendChild(inputElement1);
            cell2.appendChild(inputElement2)
            this.rowCounter++
        }
    }
}