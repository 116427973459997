import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "peekButton", "keyWord", "charboxes", "oneline", "charbox"]
  wordList = ''
  keyList = ''
  currentAnswer = ''
  language = 0
  keyword = ''
  answer=''
  
  connect() {
    document.addEventListener("nextWord", event => this.setWords(event), false)
    document.addEventListener("nextWordExtra", event => this.setWords(event), false)
    //this.wordList = JSON.parse(localStorage.getItem('wordList'))
    //this.keyList = Object.keys(this.wordList)
    //this.keyword = this.keyList[0]
    //this.answer=this.wordList[this.keyword]
    this.showButton()
    this.language=localStorage.getItem('language')
    if (this.language==1){
    this.answer = this.keyword
    }
  }

  setWords(e){
    let words = Object.values(e.detail)[0]
    this.keyword=words[0]
    this.answer=words[1]
    if (this.language==1){
      this.keyword=words[1]
      this.answer=words[0]
    }
  }

  showButton() {
      if(localStorage.getItem('peekAnswer')==0){
        this.peekButtonTarget.classList.add('hide')
      }
  }

  peekAnswer() {
    if (this.answer!=''){
      this.killFish()
      if(localStorage.getItem('letterBox')==1){
        this.showCharbox()
      }
      else {
        this.showOneline()
      }
    }
  }

  hideAnswer() {
    if(localStorage.getItem('letterBox')==1){
      this.hideCharbox()
    }
    else {
      this.hideOneline()
    }
  }

  showOneline(){
    let oneline = this.onelineTarget
    this.currentAnswer = oneline.value
    oneline.classList.add('answer')
    oneline.value = this.answer
    oneline.disabled=true
  }

  hideOneline(){
    let oneline = this.onelineTarget
    oneline.classList.remove('answer')
    oneline.value=this.currentAnswer
    oneline.disabled=false
    oneline.focus()
  }

  getInput() {
    let input=''
    for (var box of this.charboxesTarget.children) {
        input+=box.value
    }
    return input
  }

  showCharbox(){
    let container = this.charboxesTarget
    let boxes = this.charboxTargets
    this.currentAnswer = this.getInput() 
    //console.log(this.answer)
    boxes.forEach(element => element.classList.add('answer'))
    for (var i=0; i<this.answer.length; i++){
      container.children[i].value=this.answer[i]
      container.children[i].disabled=true
    }
  }

  hideCharbox() {
      let container = this.charboxesTarget
      let boxes = this.charboxTargets
      boxes.forEach(element => element.classList.remove('answer'))
      for (var i=0; i<this.answer.length; i++){
        try {
          if (this.currentAnswer[i].length==1){
            container.children[i].value=this.currentAnswer[i]
            container.children[i].disabled=false
          }
        }
        catch {
          container.children[i].value=''
          container.children[i].disabled=false
        }
      }
      try {
        container.children[this.currentAnswer.length].focus()
      }
      catch {
        //kanskje ikke dette trengs hmm
      }
  }

  killFish() {
    const eventone = document.createEvent("CustomEvent")
    eventone.initCustomEvent("addKilledFish", true, true)
    this.element.dispatchEvent(eventone)
    const event = document.createEvent("CustomEvent")
    event.initCustomEvent("killFish", true, true)
    this.element.dispatchEvent(event)
  }
}