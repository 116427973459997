import { Controller } from "stimulus"

export default class extends Controller {
  //static targets = ["tilbakeButton"]
  
  connect() {
    //console.log("Connected", this.element)
  }

  handleTilbake() {
      location.href="./index.html"
  }
}