import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["score", "currentWord", "onelineInput", "inputBox", "checkButton", "display", "container", "key", "content", "focusHolder", "focusHolderBox"]
  wordList = ''
  wordCounter = 0
  score = 0
  keyList = ''
  currentKeyWord = ''
  currentValueWord = ''
  boxCounter = 0
  language = 0
  killedFish = 0
  
  connect() {
    if (JSON.parse(localStorage.getItem('wordList'))==null) {
      location.href='/index.html'
    }
    else {
      this.shuffleWordlist()
      //this.wordList = JSON.parse(localStorage.getItem('wordList'))
      //this.keyList = Object.keys(this.wordList)
      this.currentKeyWord = this.keyList[this.wordCounter]
      this.currentValueWord = this.wordList[this.currentKeyWord]
      this.language=localStorage.getItem('language')
      this.fireNextWord()
      this.updateScore()
      this.nextWord()
      this.fireNextWordExtra()
      document.addEventListener("addKilledFish", event => this.addKilledFish())
    }
    this.timer = Date.now();
  }

  shuffleWordlist(){
    this.wordList = JSON.parse(localStorage.getItem('wordList'))
    this.keyList = Object.keys(this.wordList)

    for (var i = this.keyList.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = this.keyList[i];
      this.keyList[i] = this.keyList[j];
      this.keyList[j] = temp;
  }

    let randomized = {};
    this.keyList.forEach( key =>{
          randomized[key] = this.wordList[key];
        });
    this.wordList=randomized;
  }

  updateScore(){
    this.scoreTarget.innerHTML = `${this.score}/${this.keyList.length}`
  }

  fireNextWord() {
    const nextEvent = document.createEvent("CustomEvent")
    nextEvent.initCustomEvent("nextWord", true, true, {detail: [this.currentKeyWord, this.currentValueWord]})
    this.element.dispatchEvent(nextEvent)
  }

  fireNextWordExtra() {
    let element = this.element
    let keyword = this.currentKeyWord
    let answer = this.currentValueWord
    setTimeout (function correctAnswer() {
      const nextEvent = document.createEvent("CustomEvent")
      nextEvent.initCustomEvent("nextWordExtra", true, true, {detail: [keyword, answer]})
      element.dispatchEvent(nextEvent)
    }, 1000)
  }

  nextWord(){
      this.showWord()
      if (localStorage.getItem('letterBox')==1){
        this.makeCharboxes()
        this.fireNextWord()
        this.inputBoxTargets.forEach(element => {
          element.value=''
        })
        setTimeout(() => this.inputBoxTargets[0].focus(), 100)
      }
      else {
        this.onelineInputTarget.value=''
        setTimeout(() => this.onelineInputTarget.focus(), 100)
      }
      if (localStorage.getItem('showLetters')==1){
        this.enableKeys()
      }
  }

showWord(){
  if(this.keyList.length == this.wordCounter){
    this.saveResult()
    location.href="./highscore.html"
  }
  else {
    this.currentKeyWord = this.keyList[this.wordCounter]
    this.currentValueWord = this.wordList[this.currentKeyWord]
    if(this.language==1){
      this.currentValueWord = this.wordList[this.currentKeyWord]
      this.currentWordTarget.innerHTML= `${this.currentValueWord.toUpperCase()}`
    }
    else {
      this.currentWordTarget.innerHTML= `${this.currentKeyWord.toUpperCase()}`

    }
    this.wordCounter++
  }
}

  disableKeys(){
    this.keyTargets.forEach(key => {
      key.classList.add('disabled')
      key.disabled=true
  })
  }

  enableKeys(){
    //this.wordCounter = counter
    this.disableKeys()
    let currentWord = this.wordList[this.currentKeyWord].toLowerCase()
    if (this.language==1){
      currentWord = this.keyList[this.wordCounter-1].toLowerCase()
    }
    for (var key of this.keyTargets) {
        if (currentWord.indexOf(key.innerHTML.toLowerCase())!=-1){
            key.classList.remove('disabled')
            key.disabled=false
        }
    }
  }

  makeCharboxes(){
    this.containerTarget.innerHTML=''
    let length = this.wordList[this.currentKeyWord].length
    if (this.language==1){
      length = this.currentKeyWord.length
    }
    for (var i=0; i<length; i++){
      let inputElement = document.createElement("input");
      inputElement.type='text';
      inputElement.classList.add('charBox');
      inputElement.maxLength='1'
      inputElement.setAttribute("data-game-target", "inputBox")
      inputElement.setAttribute("data-peek-answer-target", "charbox")
      inputElement.setAttribute("data-action","input->game#charboxAutoNew keydown->game#previousCharbox click->game#charboxClick")
      inputElement.id=i
      this.containerTarget.appendChild(inputElement)

    }
  }

  enterCheckAnswer(event) {
    if (event.keyCode == 13) {
      this.onelineInputTarget.blur()
      this.checkAnswer()
    }
  }

  checkAnswer() {
    if(localStorage.getItem('letterBox')==1){
      this.focusHolderBoxTarget.focus();
      this.checkBoxes()
      }
    else{
      this.checkOneline()
    }  
  }

  checkOneline(){
    this.focusHolderTarget.focus()

    let oneline = this.onelineInputTarget
    let word = this.currentWordTarget
    let answer = this.wordList[this.currentKeyWord].toUpperCase()
    let controller = this.application.getControllerForElementAndIdentifier(this.element, 'game')
    if (this.language == 1){
      answer = this.currentKeyWord.toUpperCase()
    }
    if(oneline.value.toUpperCase()==answer){
      word.classList.add('answer')
      setTimeout (function correctAnswer() {
        controller.nextWord()
        word.classList.remove('answer')
        oneline.focus()
      }, 500)
      this.score++
      this.updateScore()
    }
    else{
      oneline.classList.add('wrongAnswer');
      word.classList.add('wrongAnswerWord')
      this.killFish()
      setTimeout(function wrongAnswer() {
          oneline.classList.remove('wrongAnswer');
          word.classList.remove('wrongAnswerWord')
          oneline.value=''
      oneline.focus()
    
      }, 1000)
    }
  }

  checkBoxes() {
    let container = this.containerTarget
    let boxes = this.inputBoxTargets;
    let currentAnswer = this.getBoxAnswer()
    let correctAnswer = this.wordList[this.currentKeyWord].toUpperCase()
    let word = this.currentWordTarget
    let controller = this.application.getControllerForElementAndIdentifier(this.element, 'game')
    this.boxCounter=0
    this.inputBoxTargets[this.inputBoxTargets.length-1].blur()
    if (this.language==1){
      correctAnswer=this.currentKeyWord.toUpperCase()
    }
    //console.log(this.wordList[this.currentKeyWord])
    if(currentAnswer.toUpperCase()==correctAnswer){
      word.classList.add('answer')
      setTimeout (function wait() {
        word.classList.remove('answer')
        controller.nextWord()
      }, 500)
      this.score++
      this.updateScore()
    }
    else {
      boxes.forEach(element => element.classList.add('wrongAnswer'))
      word.classList.add('wrongAnswerWord')
      this.killFish()
      setTimeout(function wrongAnswer() {
        word.classList.remove('wrongAnswerWord')
        boxes.forEach(element => {
          element.classList.remove('wrongAnswer')
          element.value=''
        })
        container.children[0].focus()
    }, 1000)
    }
  }

  getBoxAnswer(){
    let answer=''
    for (var box of this.containerTarget.children) {
        answer+=box.value
    }
    return answer
  }

  saveResult(){
    console.log("saveResult" + Date.now());
    let elapsedTime = Date.now() - this.timer;
    let seconds = Math.floor(elapsedTime / 1000)
    let hundreds = Math.floor((elapsedTime - seconds*1000)/10)
    let formattedSS = seconds.toString().padStart(2, "0");
    let formattedHH = hundreds.toString().padStart(2, "0");

    let time = (`${formattedSS}:${formattedHH}`);

    let score = this.scoreTarget.innerHTML
    let result = []
    result.push(time, score, this.killedFish, localStorage.getItem('peekAnswer'), localStorage.getItem('letterBox'), localStorage.getItem('showLetters'), localStorage.getItem('language'))
    let scores = JSON.parse(localStorage.getItem('scoreList'))
    if (scores==null){
      let scores = []
      scores.push(result)
      localStorage.setItem('scoreList', JSON.stringify(scores))
    }
    else {
      scores.push(result)
      localStorage.setItem('scoreList', JSON.stringify(scores))
    }
 
  }

  charboxAutoNew(event) {
    this.boxCounter=event.currentTarget.id
    try {
        this.boxCounter++
        this.inputBoxTargets[this.boxCounter].focus()
    }
    catch{
      this.checkAnswer()
    }
  }
  
  previousCharbox(event) {
    try{
      if (event.keyCode==8){
        this.boxCounter--
        this.inputBoxTargets[this.boxCounter].focus()
        this.inputBoxTargets[this.boxCounter].value=''
      }
      else if (event.keyCode==9){
        this.boxCounter=event.currentTarget.id
      }
    }
    catch {
      if(event.keyCode==8){
        this.boxCounter=0
      }
    }
  }

  charboxClick(event){
    this.boxCounter=event.currentTarget.id
  }

  keyPressed(event) {
    let key = event.currentTarget
    //console.log(event.keyCode)
    if (localStorage.getItem('letterBox')==1){
      /*if(event.keyCode==13){
        //console.log('enter')
        this.boxCounter=0
      }*/
      this.inputBoxTargets[this.boxCounter].value=key.innerHTML
      try{
        this.boxCounter++
        this.inputBoxTargets[this.boxCounter].focus()
      }
      catch{
        this.checkAnswer()
      }
    }
    else {
      this.onelineInputTarget.value=this.onelineInputTarget.value+key.innerHTML
      //this.onelineInputTarget.focus()
    }

  }

  killFish() {
    const event = document.createEvent("CustomEvent")
    event.initCustomEvent("killFish", true, true)
    this.element.dispatchEvent(event)
    this.addKilledFish()
  }

  addKilledFish() {
    this.killedFish++
  }




}