import { Controller } from "stimulus"
//import timer_controller from "./timer_controller"

export default class extends Controller {
 //static targets = ["startButton"]
  
  connect() {
    //console.log("Connected", this.element)
  }

  startGame() {
    let wordList = JSON.parse(localStorage.getItem('wordList'))
    if( wordList == null || Object.keys(wordList).length === 0 ){
      const event = document.createEvent("CustomEvent")
      event.initCustomEvent("openModalError", true, true, {type: "start"})
      this.element.dispatchEvent(event)

    }
    else{
      location.href="./game.html"
    }
  }
}