import { Controller } from "stimulus"
import glosepusWink from "../../icons/glosepus_wink.svg"
import glosepus from "../../icons/glosepus.svg"
export default class extends Controller {
  static targets = ["img"]
  
  connect() {
    //console.log("Connected", this.element)
    this.wink()
  }

  disconnect() {
      this.stopWinking()
  }

  wink() {
      let img = this.imgTarget
      let winkPus = setInterval(function one(){
        img.src = glosepusWink
        }, 5000);
      let vanligPus = setInterval(function two() {
        img.src = glosepus
        }, 2000);
  }

  stopWinking() {
      this.imgTarget.src= glosepus
  }
}