import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["currentword"]
  
  connect() {
    //console.log("Connected", this.element)
    document.addEventListener("nextWord", event => this.resize())
    window.addEventListener('resize', event => this.resize())
    this.resize()
  }

  resize() {
      let docWidth = document.documentElement.offsetWidth;
      //console.log(this.currentwordTarget.offsetHeight)
      while (this.currentwordTarget.scrollWidth > docWidth || this.currentwordTarget.scrollHeight>120) {
        let currentSize = window.getComputedStyle(this.currentwordTarget, null).getPropertyValue('font-size').substring(0,2)
        this.currentwordTarget.style.fontSize=`${currentSize*0.95}px`
      }
  }
}