import { Controller } from "stimulus"
import fishRed from "../../icons/fish_red.svg"
import fishGreen from "../../icons/fish_green.svg"
import eyeIconRedGreen from "../../icons/eye_icon_green.svg"
import boxesIconGreen from "../../icons/boxes_icon_green.svg" 
import keyIconGreen from "../../icons/key_icon_green.svg"
export default class extends Controller {
  static targets = ["round", "result", "score", "time", "row", "symbols", "fish", "language", "resultSummary"]
  
  sortedScores = []
  activeLanguage = ""
  
  
  connect() {
    this.scores = JSON.parse(localStorage.getItem('scoreList'))
    this.mainLanguageScores = this.scores.filter(score => score[score.length - 1] == 0)
    this.secondLanguageScore = this.scores.filter(score => score[score.length - 1] == 1)

    if (localStorage.getItem('language') === "0") {
      this.scores = this.mainLanguageScores
      this.sortResult(this.mainLanguageScores)
      this.activeLanguage = "main"
      this.languageTarget.innerHTML = __("FRA NORSK")
    } else {
      this.scores = this.secondLanguageScore
      this.sortResult(this.secondLanguageScore)
      this.activeLanguage = "second"
      this.languageTarget.innerHTML = __("FRA ANNET")
    }
    this.showTable()
    this.setRound()

  }

  showOtherTable() {
    this.resetTable();
    this.resultSummaryTarget.classList.add("hide")
    if (this.activeLanguage === "main") {
      this.scores = this.secondLanguageScore
      this.activeLanguage = "second"
      this.languageTarget.innerHTML = __("FRA ANNET")
      this.sortResult(this.secondLanguageScore)
    } else {
      this.scores = this.mainLanguageScores
      this.activeLanguage = "main"
      this.languageTarget.innerHTML = __("FRA NORSK")
      this.sortResult(this.mainLanguageScores)
    }
    this.showTable()
  }

  resetTable() {
    try {
      for (var i=0; i<5; i++){
        this.scoreTargets[i].innerHTML= "---"
        this.timeTargets[i].innerHTML= "--:--"
        for (var k=0; k<this.sortedScores[i][3]; k++){
          this.fishTargets[i].children[k].src = fishGreen
        }
        this.rowTargets[i].classList.remove("current")
      }
    } catch (err) {
    }
  }

  showTable() {
    try {
      for (var i=0; i<5; i++){
            let result = this.scores[this.sortedScores[i][0]]
            this.scoreTargets[i].innerHTML=result[1]
            this.timeTargets[i].innerHTML=result[0]
              for (var k=0; k<this.sortedScores[i][3]; k++){
                this.fishTargets[i].children[k].src = fishRed
              }
              if (result[3]==1) {
                this.symbolsTargets[i].children[0].src = eyeIconRedGreen
              }
              if (result[4]==1) {
                this.symbolsTargets[i].children[1].src = boxesIconGreen
              }
              if (result[5]==1) {
                this.symbolsTargets[i].children[2].src = keyIconGreen
              }
          }
      }
      catch (err){
        console.log(err);
        //Hvis det ikke er spilt tre runder allerede
      }


  }

  setRound(){
      this.roundTarget.innerHTML=`${this.scores.length}:`
      let counter = 0
      let index = 0
      this.sortedScores.forEach(element => {
          if (element[0]==(this.scores.length-1)) {
              index=counter
          }
          counter++
      })
      console.log(this.sortedScores);
      console.log(index);
      if (index==0){
        index=''
        this.rowTargets[0].classList.add('current')
        this.resultTarget.classList.add('hide')
      }
      else if (index==1) {
        this.rowTargets[1].classList.add('current')
        index=index+1 
        index = index + __("SecondRoundEnding")
      }
      else if (index==2) {
        this.rowTargets[2].classList.add('current')
        index=index+1
        index = index + __("ThirdRoundEnding")
      }
      else {
        index=index+1
        index = index + __("RestRoundEnding")
      }
      this.resultTarget.innerHTML=`${index}`
  }

  /*delete(){
      localStorage.removeItem('peekAnswer')
      localStorage.removeItem('letterBox')
      localStorage.removeItem('showLetters')
      console.log('hello')
  }*/

  sortResult(scores){
    let numberScores = []
    let counter = 0
    scores.forEach(element => {
        let list = []
        let time = element[0].substring(0,2)+element[0].substring(3)
        let slash = element[1].indexOf('/')
        let score = element[1].substring(0,slash) / element[1].substring(slash+1)
        let fish = element[2]
        list.push(counter, time, score, fish)
        numberScores.push(list)
        counter++
    });
    this.sortedScores = numberScores.sort((a, b) => {
        if (a[2] == b[2]) {
            if(a[3] == b[3]){
                return a[1] - b[1]
            }
          return a[3] - b[3];
      }
      return b[2] - a[2];
      });
  }
}