import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modalView", "confirmButton", "cancelButton"]

  
  connect() {
    //console.log("Connected", this.element)
    document.addEventListener("openModalDelete", event => this.openModal())

    this.keyboardfocusableElements = document.querySelectorAll(
      'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
    )
  }

  disconnect() {
      this.window.removeEventListener("openModalDelete", this.openModal())
  }

  openModal() {
    for (const key in this.keyboardfocusableElements) {
      if (Object.hasOwnProperty.call(this.keyboardfocusableElements, key)) {
        const element = this.keyboardfocusableElements[key];
        if (element.parentElement.className != "buttons") {
          element.disabled = true
        }
      }
    }

    let modal = this.modalViewTarget
    modal.classList.remove('hide')
    this.confirmButtonTarget.focus()
    //this.confirmButtonTarget.tabIndex='1'
    //this.cancelButtonTarget.tabIndex='2'
    window.onclick = (event) => {
      if (event.target == modal) {
        this.cancel();
      }
    }
  }

  confirm() {
    localStorage.removeItem('scoreList')
    location.href="./index.html" 
  }

  cancel() {
    for (const key in this.keyboardfocusableElements) {
      if (Object.hasOwnProperty.call(this.keyboardfocusableElements, key)) {
        const element = this.keyboardfocusableElements[key];
        element.disabled = false
      }
    }

    this.modalViewTarget.classList.add('hide')
  }
}