import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["charboxes", "oneline", "footer", "checkButton", "nextButton", "buttons", "focusHolder", "focusHolderBox"]
  
  connect() {
    //console.log("Connected", this.element)
    this.showInput()
  }

  showInput(){
        if(localStorage.getItem('letterBox')==1){
            this.onelineTarget.classList.add('hide')
            this.checkButtonTarget.classList.add('hide')
            this.nextButtonTarget.classList.add('hide')
            this.buttonsTarget.classList.add('hide')
            this.focusHolderTarget.classList.add('hide')
          }
          else {
            this.charboxesTarget.classList.add('hide')
            this.footerTarget.classList.add('hide')
            this.focusHolderBoxTarget.classList.add('hide')
        }
  }

  
}