import { Controller } from "stimulus"
import eyeIconGreen from "../../icons/eye_icon_green.svg"
import eyeIconGrey from "../../icons/eye_icon_grey.svg"
import toggleOn from "../../icons/toggle_on.svg"
import toggleOff from "../../icons/toggle_off.svg"
import boxesIconGreen from "../../icons/boxes_icon_green.svg" 
import boxesIconGrey from "../../icons/boxes_icon_grey.svg" 
import keyIconGreen from "../../icons/key_icon_green.svg"
import keyIconGrey from "../../icons/key_icon_grey.svg"

export default class extends Controller {
  static targets = ["peekAnswerImg", "letterBoxImg", "showLettersImg", "toggleImg", "checkbox", "checkboxPa", "checkboxSl", "checkboxLb"]
  //static values = {checkbox: Number}
  
  connect() { //initialize()??
    //console.log("Connected", this.element)
    this.initializeHelpers()
    this.loadToggles()
  }

  initializeHelpers(){
    if (localStorage.getItem('peekAnswer')==null){
        localStorage.setItem('peekAnswer', 0)
      }
    if (localStorage.getItem('letterBox')==null){
        localStorage.setItem('letterBox', 0)
      }
    if (localStorage.getItem('showLetters')==null){
        localStorage.setItem('showLetters', 0)
      }
  }

  handlePeekAnswer() {
      if (this.checkboxPaTarget.checked) {
          this.peekAnswerImgTarget.src= eyeIconGreen
          this.toggleImgTargets[0].src= toggleOn
          localStorage.setItem('peekAnswer', 1)
      }
      else {
        this.peekAnswerImgTarget.src= eyeIconGrey
        this.toggleImgTargets[0].src= toggleOff
        localStorage.setItem('peekAnswer', 0)
      }
  }

  handleLetterBox() {
    if (this.checkboxLbTarget.checked) {
        this.letterBoxImgTarget.src= boxesIconGreen
        this.toggleImgTargets[1].src= toggleOn
        localStorage.setItem('letterBox', 1)
    }
    else {
        this.letterBoxImgTarget.src= boxesIconGrey
        this.toggleImgTargets[1].src= toggleOff
        localStorage.setItem('letterBox', 0)
    }
}

  handleShowLetters() {
    if (this.checkboxSlTarget.checked) {
        this.toggleImgTargets[2].src=  toggleOn
        this.showLettersImgTarget.src= keyIconGreen
        localStorage.setItem('showLetters', 1)
    }
    else {
      this.toggleImgTargets[2].src=toggleOff
      this.showLettersImgTarget.src=keyIconGrey
      localStorage.setItem('showLetters', 0)
    }
}

    loadToggles () {
        if (localStorage.getItem('peekAnswer') == 1){
            this.peekAnswerImgTarget.src= eyeIconGreen
            this.toggleImgTargets[0].src= toggleOn
            this.checkboxPaTarget.checked=true
        }
        if (localStorage.getItem('letterBox') == 1){
            this.letterBoxImgTarget.src= boxesIconGreen
            this.toggleImgTargets[1].src= toggleOn
            this.checkboxLbTarget.checked=true
        }
        if (localStorage.getItem('showLetters') == 1){
            this.showLettersImgTarget.src= keyIconGreen
            this.toggleImgTargets[2].src= toggleOn
            this.checkboxSlTarget.checked=true
        }
    }

    enterPressedPA(event) {
        if (event.keyCode == 13) {
            this.checkboxPaTarget.checked=!this.checkboxPaTarget.checked
            this.handlePeekAnswer()
        }
    }

    enterPressedLB(event) {
        if (event.keyCode == 13) {
            this.checkboxLbTarget.checked=!this.checkboxLbTarget.checked
            this.handleLetterBox()
        }
    }

    enterPressedSL(event) {
        if (event.keyCode == 13) {
            this.checkboxSlTarget.checked=!this.checkboxSlTarget.checked
            this.handleShowLetters()
        }
    }

}