import { Controller } from "stimulus"
//import timer_controller from "./timer_controller"

export default class extends Controller {
 //static targets = ["startButton"]
  
  connect() {
    //console.log("Connected", this.element)
  }

  startGame() {
    if(JSON.parse(localStorage.getItem('wordList'))==null){
      location.href="./index.html"
      
    }
    else{
      location.href="./game.html"
    }
  }
}