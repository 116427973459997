import { Controller } from "stimulus"
import QrCodeWithLogo from "qrcode-with-logos";
import glosepus from "../../icons/glosepus.png"

export default class extends Controller {
  static targets = ["canvas"]
  
  connect() {
    window.addEventListener("updateQRCode", e => {
      this.makeQRCode(e.detail.urlValue)
    })
    let string = window.location.origin + window.location.pathname.replace("/share.html", "") + this.makeUrlParams()
    this.makeQRCode(string)
  }

  makeUrlParams() {
    let urlParamString = "?"
    let wordList = JSON.parse(localStorage.getItem('wordList'))

    Object.keys(wordList).forEach(element => {
      urlParamString = urlParamString.concat(element + "=" +wordList[element] + "&")
    });

    return urlParamString.slice(0, -1)
  }

  makeQRCode(urlValue) {
    new QrCodeWithLogo({
      canvas: this.canvasTarget,
      content: urlValue,
      width: 180,
      nodeQrCodeOptions: {
        color: {
          dark: "#000000",
        },
        errorCorrectionLevel: "M"
      },
      logo: {
        src: glosepus,
        logoSize: 0.15,
      }
    }).toImage();
  }
}