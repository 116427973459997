import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  
  connect() {
  }

  handleClick() {
    if(JSON.parse(localStorage.getItem('wordList'))==null){
      const event = document.createEvent("CustomEvent")
      event.initCustomEvent("openModalError", true, true, {type: "share"})
      this.element.dispatchEvent(event)

    }
    else{
      location.href="./share.html" 
    }
  }

}