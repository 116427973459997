import { Controller } from "stimulus"
import redFish from "../../icons/fish_red.svg"

export default class extends Controller {
  static targets = ["fish"]
  deadFish = -1
  
  connect() {
    document.addEventListener("killFish", event => this.killFish())
  }

  killFish() {
    this.deadFish++
    try {
      this.fishTargets[this.deadFish].src = redFish
    }
    catch {}
  }
}