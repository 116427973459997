import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modalView", "confirmButton", "cancelButton", "content"]
  
  connect() {
    this.type = ""
    document.addEventListener("openModalWarningFlip", event => this.openModal(event))
    document.addEventListener("openModalWarningLoad", event => this.openModal(event))

    this.keyboardfocusableElements = document.querySelectorAll(
      'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
    )
    
    const event = document.createEvent("CustomEvent")
      event.initCustomEvent("modalInit", true, true)
      this.element.dispatchEvent(event)
  }

  disconnect() {
      this.window.removeEventListener("openModalWarningFlip", this.openModal())
      this.window.removeEventListener("openModalWarningLoad", this.openModal())
  }

  openModal(e) {
    if (e.type === "openModalWarningFlip") {
      this.type = "Flip"
      this.contentTarget.innerHTML = __("Ved å vende ordlisten slettes highscoren. <br> Vil du fortsette?")
      this.confirmButtonTarget.innerHTML = __("Ja, vend ordlisten")
      this.cancelButtonTarget.innerHTML = __("Nei, ikke vend")
    } else if (e.type === "openModalWarningLoad") {
      this.type = "Load"
      this.contentTarget.innerHTML = __("Ved å laste inn ny ordliste slettes highscoren. <br> Vil du fortsette?")
    }
    for (const key in this.keyboardfocusableElements) {
      if (Object.hasOwnProperty.call(this.keyboardfocusableElements, key)) {
        const element = this.keyboardfocusableElements[key];
        if (element.parentElement.className != "buttons") {
          element.disabled = true
        }
      }
    }

    let modal = this.modalViewTarget
    modal.classList.remove('hide')
    this.confirmButtonTarget.focus()
    window.onclick = (event) => {
      if (event.target == modal) {
          this.cancel();
      }
    }
  }

  confirm() {
    localStorage.removeItem('scoreList')
    location.href="./index.html" 

    const event = document.createEvent("CustomEvent")
      event.initCustomEvent("confirmSwitch" + this.type, true, true)
      document.dispatchEvent(event)

  }

  cancel() {
    for (const key in this.keyboardfocusableElements) {
      if (Object.hasOwnProperty.call(this.keyboardfocusableElements, key)) {
        const element = this.keyboardfocusableElements[key];
        element.disabled = false
      }
    }

    this.modalViewTarget.classList.add('hide')
  }
}