import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modalView", "okButton", "errorText"]
  connect() {
    this.type = {
      "start": __("Glosepus trenger noen ord før du kan starte spillet."),
      "share": __("Glosepus trenger noen ord før du kan dele spillet."),
    }
    this.keyboardfocusableElements = document.querySelectorAll(
      'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
    )
    
    //console.log("Connected", this.element)
    document.addEventListener("openModalError", event => this.openModal(event))
  }

  disconnect() {
      this.window.removeEventListener("openModalError", this.openModal())
  }

  openModal(e) {
      for (const key in this.keyboardfocusableElements) {
        if (Object.hasOwnProperty.call(this.keyboardfocusableElements, key)) {
          const element = this.keyboardfocusableElements[key];
          if (element.parentElement.className != "buttons") {
            element.disabled = true
          }
        }
      }

      let modal = this.modalViewTarget
      this.errorTextTarget.innerHTML = this.type[e.detail.type]
      modal.classList.remove('hide')
      this.okButtonTarget.focus()
      //console.log(this.okButtonTarget.tabIndex)
      //this.okButtonTarget.tabIndex='0'
      //console.log(this.okButtonTarget.tabIndex)
      window.onclick= (event) => {
        if (event.target == modal) {
            this.close();
        }
      }
  }

  close() {
    for (const key in this.keyboardfocusableElements) {
      if (Object.hasOwnProperty.call(this.keyboardfocusableElements, key)) {
        const element = this.keyboardfocusableElements[key];
        element.disabled = false
      }
    }

    this.modalViewTarget.classList.add('hide')
  }
}