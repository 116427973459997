import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["key", "keyboard"]
  
  connect() {
    this.showKeyboard()
    //document.addEventListener("resizeKeyboard", event => this.resizeKeyboard())
    //window.addEventListener('resize', event => this.resizeKeyboard())
    //this.resizeKeyboard()
  }

  //enableKeys og handleClick ligger i game_controller

  showKeyboard(){
      if (localStorage.getItem('showLetters')==0) {
          this.keyboardTarget.classList.add('hide')
      }
      else{
          //this.resizeKeyboard()
      }

  }

  keyPressed(){
    
  }

  //Brukes ikke per nå. Lytter heller til @media skjermstørrelse
  resizeKeyboard() {
    //let docWidth = document.documentElement.offsetWidth;
    //console.log(this.currentwordTarget.offsetHeight)
    if (this.keyboardTarget.offsetHeight > 150) {
      let currentSize = window.getComputedStyle(this.keyTargets[0], null).getPropertyValue('font-size').substring(0,2)
      console.log(currentSize)
      for (var el of this.keyTargets){
        el.style.fontSize=`${currentSize*0.95}px`
      }
    }

  }


}